import { FC } from 'react';

import { SessionProvider } from 'next-auth/react';

import type { AppProps } from '@dxp-next';

const SelfServiceApp: FC<AppProps> = ({ Component, pageProps: { session, ...pageProps }, router }) => {
  return (
    <SessionProvider session={session} basePath={`${router.basePath}/api/auth`}>
      <Component {...pageProps} />
    </SessionProvider>
  );
};

export default SelfServiceApp;
